import React from 'react';
import { MicrosoftLoginButton } from 'react-social-login-buttons';
import './Login.css';
import { Card, Space, Divider } from "antd";
import logo from './logo.jpg';
import { mgr } from "../../config/authConfig";
import { Button } from "antd";
const Login = () => {

  return (
    <div className='login-container'>
      <div className='login-wrapper'>

        <Card title={<img alt="logo"  className="loginLogo" src={logo} />}>
          <Button type="primary" onClick={() => App()} block>Login to Registry</Button>
          <Divider />
          <p>
          Hestio's registry is powered by <a href="https://docs.terrakube.io/">Terrakube</a>
          </p>
        </Card>

      </div> </div>
  )
}

function App() {
  console.log(mgr);
  mgr.signinRedirect();
}

export default Login;
